<template>
  <AuthWrapper  >
     <div class="auth-contents">
       <a-form @submit.prevent="handleSubmit" :model="formState" layout="vertical">
        <center>
        <!-- <img class="auth-content-figure" :src="require('@/static/img/them_logo.jpg')" alt="" /> -->
        <sdHeading as="h3"> Change Password </sdHeading>
        <p><b>Please wait for an email from the Account Analytix Admin that will contain your temporary password. After receiving the password, update it here and then log in.</b></p>
        </center>
        <a-alert
          v-if="message != null"
          :outlined="false"
          :closable="false"
          :showIcon="false"
          :description="message"
          type="error"
        />
        <!-- <br /> -->
        <a-form-item name="username" label="Username or Email Address">
          <a-input
            type="email"
            v-model:value="formState.email"
            @input="emailField.handleChange"
            @blur="emailField.handleBlur"
            :value="emailField.value"
          />
          <a-alert
            v-if="emailField.meta.touched && !emailField.meta.valid"
            :outlined="false"
            :closable="false"
            :showIcon="false"
            message=""
            :description="emailField.errorMessage || 'Temporary Password is Required'"
            type="error"
          />
        </a-form-item>
        <a-form-item name="temp_pass" label="Temporary Password">
          <a-input
            type="password"
            v-model:value="formState.pass1"
            @input="passField.handleChange"
            @blur="passField.handleBlur"
            :value="passField.value"
          />
          <a-alert
            v-if="passField.meta.touched && !passField.meta.valid"
            :outlined="false"
            :closable="false"
            :showIcon="false"
            message=""
            :description="passField.errorMessage || 'Temporary Password is Required'"
            type="error"
          />
        </a-form-item>

        <a-form-item name="password" initialValue="123456" label="New Password">
          <a-input
            type="password"
            v-model:value="formState.password"
            placeholder="Password"
            @input="passwordField.handleChange($event)"
            @blur="passwordField.handleBlur"
            :value="passwordField.value"
          />
          <a-alert
            v-if="passwordField.meta.touched && !passwordField.meta.valid"
            :outlined="false"
            :closable="false"
            :showIcon="false"
            message=""
            :description="passwordField.errorMessage || 'Password is Required'"
            type="error"
          />
        </a-form-item>
        <a-form-item :disabled="!formMeta.valid">
          <a-button class="btn-signin" htmlType="submit" type="primary" size="large">
            {{ isLoading ? 'Loading...' : 'Update Password' }}
          </a-button>
        </a-form-item>
        
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script> 
import { computed, reactive, ref, defineComponent } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { AuthWrapper } from './style';
import { useRouter } from 'vue-router'; 
import { useField, useForm } from 'vee-validate';
export default defineComponent ({
name: 'SignIn',
components: {   AuthWrapper, },
  data() {
    return {
      email: '',
      password: '',
      pass1: ''
    };
  },

  created() {
    // Access query parameters from the URL
    this.email = this.$route.query.email;
    this.password = this.$route.query.password;
  },

    setup() {
    const { state, dispatch } = useStore();
    var isLoading = computed(() => state.auth.loading);
    const checked = ref(null);
    const router = useRouter();
    var message = ref(null);
    const emailField = useField('email', 'required|email');
    const passField = useField('password', 'required');
    const passwordField = useField('password', 'required|min:6');
    const { meta: formMeta,   } = useForm();
    const formState = reactive({
      email: '',
      pass1: '',
      password: '',
    });
    // Populate formState with query parameters
    const email = router.currentRoute.value.query.email || '';
    const password = router.currentRoute.value.query.password || '';
    formState.email = email;
    formState.pass1 = password; // If you want to use it as temporary password
    const handleSubmit = () => {
      isLoading.value = true;
      if (emailField.meta.valid && passField.meta.valid && passwordField.meta.valid) {
        dispatch('xeroUpdatePassword', formState)
          .then((response) => {
            isLoading.value = false;
            state.auth.login = true;
            router.push('/');
          })
          .catch((error) => {
            console.error('API Error:', error);
            isLoading.value = false;
            message.value = typeof error !== 'string' ? 'Wrong email id or password' : 'Invalid Credentials';
          });
      } else {
        isLoading.value = false;
        message.value = 'Please fill all fields correctly.';
      }
    };
    const onChange = (checked) => {
      checked.value = checked;
    };

    
    return {
      isLoading,
      checked,
      handleSubmit,
      onChange,
      formState, 
      emailField,
      passField,
      passwordField,
      formMeta,
      message,
    };
  },
  });
</script>
